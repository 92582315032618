import React from 'react'

function Terms() {
  return (
    <div>
      <h1>This is the Terms page...</h1>
    </div>
  )
}

export default Terms
