import React from 'react'

function Completed() {
  return (
    <div>
      <h1>Order completed! Hurray!!! </h1>
    </div>
  )
}

export default Completed
